<template>
  <button
    v-if="campaigns && campaigns.length > 1"
    class="button is-light mb-3"
    @click="showModal = true"
  >
    Add previous recipients
  </button>

  <Modal
    v-if="showModal"
    title="Recipients"
    :isSmall="true"
    @close="showModal = false"
  >
    <FormField
      label="Search"
      v-model="search"
    />
    
    <div class="table-container">
      <table class="table is-fullwidth">
        <tbody>
          <tr v-for="client in clients" :key="client.email">
            <td colspan="2">
              <label class="label mb-0">{{ client.name }}</label>
              <p class="help has-text-weight-normal">{{ client.email }}</p>
            </td>
            <td class="has-text-right">
              <button v-if="!addedClient(client)" class="button is-light" @click="addClient(client)">
                <Icon icon="plus" />
              </button>
              <button v-else class="button is-light" @click="removeClient(client)">
                <Icon icon="fas fa-minus" />
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <template v-slot:footer>
      <button
        class="button is-black"
        :disabled="!chosenClients.length"
        @click="$emit('addClients', chosenClients), showModal = false"
      >
        Add recipients
      </button>
      <button class="button" @click="showModal = false">
        Close
      </button>
    </template>
  </Modal>
</template>

<script>
import { ref, computed } from 'vue'
import getUser from '@/composables/getUser'
import getCollection from '@/composables/getCollection'
import Modal from '@/components/Modals/Modal.vue'
import FormField from '@/components/Forms/FormField.vue'
import Icon from '@/components/Icon.vue'

export default {
  props: ['clients'],
  emits: ['close', 'addClients'],
  components: { Modal, FormField, Icon },
  setup(props) {
    const { user } = getUser()
    const { documents: campaigns } = getCollection('campaigns', ['accountId', '==', user.value.uid])

    const showModal = ref(false)
    const search = ref('')

    const clients = computed(() => {
      let clients = []
      campaigns.value && campaigns.value.forEach(campaign => {
        clients = [...clients, ...campaign.clients]
      })

      let unique = [...new Map(clients.map(item => [item.email, item])).values()]
        .filter(client => 
          !props.clients.some(propClient => propClient.email === client.email)
        )

      if (search.value) {
        unique = unique.filter(client => {
          const string = client.name + client.email
          return string.toLowerCase().includes(search.value.toLowerCase())
        })
      }

      return unique
    })

    const chosenClients = ref([])

    const addedClient = (client) => {
      return chosenClients.value.includes(client)
    }

    const addClient = (client) => {
      chosenClients.value.push(client)
    }

    const removeClient = (client) => {
      chosenClients.value.splice(chosenClients.value.indexOf(client), 1)
    }
    
    return {
      campaigns,
      showModal,

      search,
      clients,
      chosenClients,
      addedClient,
      addClient,
      removeClient
    }
  }
}
</script>