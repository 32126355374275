<template>
  <FormField
    label="Select a size:"
    type="tabs"
    :options="sortedSizes"
    v-model="product.chosenSize"
  />
</template>

<script>
import { computed } from 'vue'
import FormField from '@/components/Forms/FormField.vue'

export default {
  props: ['product'],
  components: { FormField },
  setup(props) {
    const sortOrder = props.product.sizes.length > 5 ?
      ['6.5', '7', '7.5', '8', '8.5', '9', '9.5', '10', '10.5', '11', '11.5', '12', '12.5', '13'] :
      ['XS', 'S', 'M', 'L', 'XL']

    const sortedSizes = computed(() => {
      return props.product.sizes.sort((a, b ) => {
        return sortOrder.indexOf(a) - sortOrder.indexOf(b)
      }).map(size => {
        return { label: size, value: size }
      })
    })

    return {
      sortedSizes
    }
  }
}
</script>