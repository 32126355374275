<template>
  <section v-if="invite" :class="`hero container ${!showAddress && 'is-fullheight'}`">

    <div v-if="invite.expiryDate.toDate().getTime() < new Date().getTime()" class="hero-body is-flex is-justify-content-center">
      <h3 class="subtitle is-3 has-text-centered">
        Sorry, the invitation has&nbsp;expired.
      </h3>
    </div>

    <div v-else-if="invite.declinedAt" class="hero-body is-flex is-justify-content-center">
      <h3 class="subtitle is-3 has-text-centered">
        You have declined this invite, we will let {{ invite.accountName }} know.
      </h3>
    </div>

    <div v-else-if="invite.acceptedAt" class="hero-body has-text-centered mx-auto" style="max-width: 640px;">
      <h1 class="subtitle is-size-3">
        Congrats!
      </h1>
      <h3 class="is-size-5">
        Your exclusive swag is on the way! Keep an eye on your inbox for a tracking number and order status updates.
      </h3>

      <form v-if="!invite.feedbackMessage">
        <FormField
          :label="`Send a personal note to ${invite.accountName} to say thank you!`"
          type="textarea"
          v-model="message"
          class="mt-5"
          id="centered-label"
        />
        <button
          :class="`button is-black is-fullwidth mt-4 ${isPending && 'is-loading'}`"
          :disabled="!message"
          type="button"
          @click="sendMessage()"
        >
          Send message
        </button>
      </form>

      <p class="mt-6 mb-3">
        Ready to create your own campaign?
      </p>
      <router-link :to="{ name: 'SignUp' }" class="button has-text-weight-bold px-6 is-ghost">
        Sign up
      </router-link>
    </div>
    
    <div v-else-if="!showAddress" class="hero-body">
      <div class="columns">
        <div class="column is-8-tablet is-6-desktop">
          <h3 class="subtitle is-size-3 mb-0">
            Hello, {{ invite.name }}
          </h3>
          <p class="is-size-5 mb-5">
            <span class="has-text-info">{{ invite.accountName }}</span> wants to send you a gift
          </p>

          <div v-if="invite.logoUrl || invite.message" class="box">
            <figure v-if="invite.logoUrl" class="image mx-auto mb-6" style="width: 100px;">
              <img :src="invite.logoUrl">
            </figure>

            <p v-if="invite.message" class="subtitle has-text-centered mt-5">
              "{{ invite.message }}"
            </p>
          </div>

          <p class="has-text-centered mb-3">
            At Swagible, your privacy is our top priority.
          </p>
          <p class="has-text-centered mb-3">
            Rest assured, we'll never share your personal information with gift senders. Your address and size choices are kept confidential at all times. Your privacy is our commitment, and it's always protected.
          </p>
        </div>

        <div class="column is-4 px-0">
          <div class="scrollable px-4 border-left">
            <h3 class="subtitle is-size-3 mb-0">
              Items
            </h3>
            <p>
              Select <span class="has-text-info">{{ invite.chooseType }}</span> items to receive
            </p>

            <Product
              v-for="product in invite.products"
              :key="product.id"
              type="invite"
              :product="product"
              :isAdded="addedProduct(product)"
              @toggleProduct="toggleProduct"
            />
          </div>
          
          <div class="has-text-right">
            <p class="subtitle">
              {{ addedProducts.length }}/{{ invite.chooseType }}
            </p>
            <button
              class="button is-black px-6"
              :disabled="!addedProducts.length"
              @click="showAddress = true"
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>

    <div v-else-if="showAddress" class="hero-body">
      <div class="columns is-justify-content-space-between">
        <div class="column is-8-tablet is-6-desktop">
          <button class="button is-ghost pl-0" @click="showAddress = false">
            <Icon icon="chevron-left" />
            <span>Back</span>
          </button>

          <h3 class="subtitle is-size-3">
            Where should we send your swag?
          </h3>

          <FormField
            label="Name *"
            v-model="invite.name"
          />

          <div class="field">
            <label class="label">Mailing Address *</label>
            <div class="control">
              <VueGoogleAutocomplete
                id="map"
                classname="input"
                placeholder="Start typing..."
                v-on:placechanged="getAddressData"
              />
            </div>
          </div>

          <div :class="`columns is-multiline mt-5 ${!shippingInfo.streetAddress && 'is-disabled'}`">
            <FormField
              label="Country *"
              class="column is-6"
              v-model="shippingInfo.country"
            />

            <FormField
              label="Address line 1 *"
              class="column is-12"
              v-model="shippingInfo.streetAddress"
            />

            <FormField
              label="Address line 2"
              class="column is-12"
              v-model="shippingInfo.streetAddress2"
            />

            <FormField
              label="Unit/Suit"
              class="column is-4"
              v-model="shippingInfo.unit"
            />

            <FormField
              label="City *"
              class="column is-8"
              v-model="shippingInfo.city"
            />

            <FormField
              label="Province/State *"
              class="column is-6"
              v-model="shippingInfo.province"
            />

            <FormField
              label="Postal code *"
              class="column is-6"
              v-model="shippingInfo.postal"
            />

            <FormField
              label="Phone number"
              class="column is-12"
              v-model="shippingInfo.phone"
            />
          </div>

          <p class="help">* indicates required field</p>
        </div>

        <div class="column is-4 is-flex is-flex-direction-column has-offset-top">
          <div class="box">
            <h3 class="subtitle">
              Items
            </h3>
            <OrderItem
              v-for="product in addedProducts"
              :key="product.id"
              :item="product"
            />
          </div>

          <div class="has-text-right mt-auto">
            <button
              :class="`button px-6 is-black ${isPending && 'is-loading'}`"
              :disabled="!invite.name || !shippingInfo.streetAddress || !shippingInfo.city || !shippingInfo.province || !shippingInfo.postal"
              @click="acceptInvite()"
            >
              Submit
            </button>
          </div>

        </div>
      </div>
    </div>
  </section>

  <footer class="has-text-centered p-3">
    <figure class="image mx-auto" style="width: 100px;">
      <img src="@/assets/logo3.svg" />
    </figure>
    <p class="help mt-2">
      &copy;2023 Swagible, Inc. All rights reserved.
    </p>
    <a href="/" target="_blank" class="help has-text-dark">
      Privacy Policy
    </a>
  </footer>
</template>

<script>
import { inject, ref, computed, reactive } from 'vue'
import getDocument from '@/composables/getDocument'
import useDocument from '@/composables/useDocument'
import useCollection from '@/composables/useCollection'
import Product from '@/components/Product/Product.vue'
import AddProductModal from '@/components/Modals/AddProductModal.vue'
import VueGoogleAutocomplete from 'vue-google-autocomplete'
import FormField from '@/components/Forms/FormField'
import Icon from '@/components/Icon'
import OrderItem from '@/components/Campaign/OrderItem.vue'
import { projectFirestore, timestamp, deleteField } from '@/firebase/config'
import { generateCampaignItemsHTML } from '@/utils/utils'
import filters from '@/utils/filters'

export default {
  props: ['campaignId', 'inviteId'],
  components: { Product, AddProductModal, VueGoogleAutocomplete, FormField, Icon, OrderItem },
  setup(props) {
    const { document: invite, error } = getDocument('invites', props.inviteId)
    const { updateDoc: updateInvite } = useDocument('invites', props.inviteId)
    console.log('invite', invite)

    const addedProducts = ref([])
    const showAddress = ref(false)
    const isPending = inject('isPending')

    const addedProduct = (product) => {
      return addedProducts.value.find(x => x.id === product.id)
    }

    const toggleProduct = (product) => {
      if (!addedProduct(product)) {
        addedProducts.value.push(product)
        if (addedProducts.value.length > invite.value.chooseType) addedProducts.value.shift()
      } else {
        const index = addedProducts.value.findIndex(x => x.id === product.id)
        if (index > -1) {
          addedProducts.value.splice(index, 1)
        }
      }
    }

    const shippingInfo = reactive({
      country: '',
      streetAddress: '',
      streetAddress2: '',
      city: '',
      province: '',
      postal: '',
      unit: '',
      longitude: '',
      latitude: '',
      phone: '',
    })

    const getAddressData = (data) => {
      shippingInfo.country = data.country
      shippingInfo.streetAddress = `${data.street_number} ${data.route}`
      shippingInfo.city = data.administrative_area_level_2
      shippingInfo.province = data.administrative_area_level_1
      shippingInfo.postal = data.postal_code
      shippingInfo.latitude = data.latitude
      shippingInfo.longitude = data.longitude
    }

    const acceptInvite = async () => {
      isPending.value = true
      await updateInvite({
        acceptedAt: timestamp(),
        chosenProducts: addedProducts.value,
        shippingInfo: shippingInfo,
      })

      const { addDoc: addEmail } = useCollection('mail')
      await addEmail({
        to: invite.value.accountEmail,
        templateId: 'd-8dddf31387184bb68868461df0f8fe82', // SWAGIBLE:RECIPIENTRESPONDED
        dynamic_template_data: {
          recipient_first_name: invite.value.name,
          recipient_message_html: generateCampaignItemsHTML(addedProducts.value),
          dashboard_url: `${process.env.VUE_APP_BASE_URL}/dashboard`
        }
      })

      await addEmail({
        to: invite.value.email,
        templateId: 'd-53acace893d9406c8efbfb23714f5f9d', // SWAGIBLE:RECIPIENTCLAIMEDSWAG
        dynamic_template_data: {
          recipient_first_name: invite.value.name,
          onboarding_url: 'TODO onboarding_url',
        }
      })

      // TODO these triggers
      // const { updateDoc: updateMailTrigger } = useCollection('mailTriggers', props.userId)
      // await updateMailTrigger({
      //   [props.inviteId]: deleteField()
      // })

      const { addDoc: addNotification } = useCollection('accounts', invite.value.accountId, 'notifications')
      await addNotification({
        type: 'acceptInvite',
        message: `${invite.value.name} has responded`,
        campaignId: invite.value.campaignId,
        seen: false,
        created: timestamp()
      })
      checkIfLastInvite()
      isPending.value = false
    }

    const declineInvite = async () => {
      isPending.value = true
      await updateInvite({
        declinedAt: timestamp()
      })
      checkIfLastInvite()
      isPending.value = false
    }

    const message = ref('')
    const sendMessage = async () => {
      isPending.value = true
      await updateInvite({
        feedbackMessage: message.value,
        messageDate: timestamp()
      })
      isPending.value = false
    }

    const checkIfLastInvite = () => {
      isPending.value = true
      console.log('checkIfLastInvite')

      projectFirestore
        .collection('invites')
        .where('campaignId', '==', invite.value.campaignId)
        .get()
        .then((snapshot) => {
          const unansweredInvite = snapshot.docs.find(doc => !doc.data().acceptedAt)
          if (!unansweredInvite) {
            campaignCompleted()
          }
          isPending.value = false
        })
    }

    const campaignCompleted = async () => {  
      console.log('campaignCompleted')  
      isPending.value = true

      // TODO send email!! when get back in sendgrid
      // const { addDoc: addEmail } = useCollection('mail')
      // await addEmail({
      //   to: invite.email,
      //   templateId: 'd-f02678b92650435abdcc0740458c6f7b', // SWAGIBLE:RECIPIENTINVITE48HREMINDER
      //   dynamic_template_data: {
      //   }
      // })

      const { addDoc: addNotification } = useCollection('accounts', invite.value.accountId, 'notifications')
      await addNotification({
        type: 'campaignComplete',
        message: `${invite.value.campaignName} is ready to process.`,
        campaignId: invite.value.campaignId,
        seen: false,
        created: timestamp()
      })
      isPending.value = false
    }

    return {
      invite,
      error,

      showAddress,
      isPending,

      addedProducts,
      addedProduct,
      toggleProduct,

      shippingInfo,
      getAddressData,
      acceptInvite,
      declineInvite,

      message,
      sendMessage,

      ...filters
    }
  }
}
</script>

<style scoped>
@media screen and (min-width: 769px) {
  .hero.is-fullheight {
    height: calc(100svh - 100px);
    min-height: 0;
    overflow: visible;
  }

  .border-left {
    border-left: 1px solid;
  }

  .scrollable {
    height: calc(100svh - 10rem);
    overflow-y: scroll;
  }

  .has-offset-top {
    margin-top: 125px;
  }
}

@media screen and (max-width: 768px) {
  .button.is-black {
    width: 100%;
  }
}

.hero.is-fullheight .hero-body {
  display: block;
}

.columns {
  justify-content: space-between;
}

.is-disabled {
  opacity: .5;
  pointer-events: none;
}
</style>