<template>
  <div :class="isPending && 'is-hidden'">
    <label class="label">Card Number</label>
    <div class="input mb-3">
      <StripeElement :element="CardNumberElement" @change="event = $event" class="column" />
    </div>
    <div class="columns is-mobile">
      <div class="column pr-0">
        <label class="label">Card CVC</label>
        <div class="input">
          <StripeElement :element="CardExpiryElement" @change="event = $event" class="column" />
        </div>
      </div>
      <div class="column">
        <label class="label">Card Expiry</label>
        <div class="input">
          <StripeElement :element="CardCvcElement" @change="event = $event" class="column" />
        </div>
      </div>
    </div>

    <div v-if="ctaText" class="level is-mobile_ is-justify-content-flex-end mb-3">
      <button
        class="button is-black"
        v-html="ctaText"
        @click="handleNewCardSubmit()"
      />
    </div>

    <article v-if="error" class="message is-danger">
      <div class="message-body">
        Stripe Error: {{ error }}
      </div>
    </article>
    
    <article v-if="error && event.error" class="message is-danger">
      <div class="message-body">
        {{ event.error.message }}
      </div>
    </article>
  </div>

  <p v-if="isPending" class="mb-5">
    Please wait, connecting Credit Card...
  </p>
</template>

<script>
import '@stripe/stripe-js'
import { useStripe, StripeElement } from 'vue-use-stripe'
import { ref, inject } from 'vue'
import getUser from '@/composables/getUser'
import useCollection from '@/composables/useCollection'

export default {
  props: ['ctaText'],
  components: { StripeElement },
  emits: ['isPending', 'complete'],
  setup(_, { emit }) {
    const { user } = getUser()
    const event = ref({})
    // const isPending = inject('isPending')
    const isPending = ref(false)
    const error = ref(false)

    const style = {
      base: {
        color: '#363636',
        fontSize: '16px',
        fontFamily: 'BlinkMacSystemFont, -apple-system, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif',
        fontSmoothing: 'antialiased',
        ':-webkit-autofill': {
          color: '#ccc',
        },
        '::placeholder': {
          color: '#ccc',
        },
      },
      invalid: {
        color: '#f14668',
      },
    }

    const blah = useStripe({
      key: 'pk_test_51KZNTXKadYOskHW4rpPQKQcMHehyqoDycbDeNnsv8CRpJbnnZyshFCiC5QK8AhOCuTAQxCFwvNiBeR82CLJ6yxE400caxReWYn',
    })
    console.log('blah', blah)

    const { stripe, elements: [CardNumberElement, CardExpiryElement, CardCvcElement] } = useStripe({
      key: 'pk_test_51KZNTXKadYOskHW4rpPQKQcMHehyqoDycbDeNnsv8CRpJbnnZyshFCiC5QK8AhOCuTAQxCFwvNiBeR82CLJ6yxE400caxReWYn',
      elements: [
        { type: 'cardNumber', options: { showIcon: true, style } },
        { type: 'cardCvc', options: { style } },
        { type: 'cardExpiry', options: { style } }
      ]
    })

    const getIntent = () => {
      return fetch(`https://api.stripe.com/v1/setup_intents?customer=${user.value.uid}`, {
        method: 'post',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: 'Bearer ' + 'sk_test_51KZNTXKadYOskHW4gOZXr482pp0wuccBuKTqIDCzKH1zfAaPTcVASSNz31RGASRJiA9CA3kRd1Gwbow6R14eBtq000wPTXeUTv',
        }
      }).then((response) => response.json())
        .then((setupIntent) => setupIntent)
    }

    const handleNewCardSubmit = async () => {
      console.log('handleNewCardSubmit')
      if (!event.value.complete) return
      error.value = ''
      isPending.value = true
      emit('isPending', true)

      const intent = await getIntent()
      if (!intent.client_secret) {
        return
      }

      const { setupIntent, error: setupIntentError } = await stripe.value.confirmCardSetup(intent.client_secret, {
        payment_method: {
          card: CardNumberElement.value,
          // TODO! billing_details: { email: email }
        }
      })

      if (setupIntent && !setupIntentError) {
        const { addDoc: addStripeCustomer } = useCollection('stripe_customers', user.value.uid, 'payment_methods')
        await addStripeCustomer({
          id: setupIntent.payment_method
        })
        console.log('complete....')
        emit('complete', setupIntent.payment_method)
      } else {
        error.value = setupIntentError.message
        isPending.value = false
      }
    }

    return {
      error,
      event,
      CardNumberElement,
      CardExpiryElement,
      CardCvcElement,
      handleNewCardSubmit,
      isPending,
    }
  }
}
</script>